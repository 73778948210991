<script>
import {
  CONTRIBUTION_STATUS,
  STEP_TRADE_CONTRIBUTIONS,
  STEP_EXTERNAL_CONTRIBUTIONS,
} from '@src/utils/consts'
import {
  allTradeContributions,
  createTradeContribution,
  updateTradeContributionStatus,
  finishTradeContributions,
  getTradeContributionPermissions,
} from '@comp/document/tradeContributions/queries'
import { useIndexStore } from '@src/store/index.js'
import TradeContributionsButtons from '@comp/document/tradeContributions/TradeContributionsButtons.vue'
import TradeContributionsHeader from '@comp/document/tradeContributions/TradeContributionsHeader.vue'
import TradeContributionsTable from '@comp/document/tradeContributions/TradeContributionsTable.vue'
import InformationPopup from '@comp/utils/InformationPopup.vue'
import Threads from '@comp/document/threads/Threads.vue'

export const ListMode = Object.freeze({
  TRADE_CONTRIBUTIONS: 'tradeContributions',
  THREADS: 'threads',
})

export default {
  name: 'TradeContributions',
  components: {
    InformationPopup,
    TradeContributionsButtons,
    TradeContributionsHeader,
    TradeContributionsTable,
    Threads,
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    documentVersionId: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'nextStep',
    'tradeContributionsChanged',
    'previewUpdateRequired',
    'reduceDocumentInformationRequest',
    'expandDocumentInformationRequest',
  ],
  data () {
    return {
      documentVersion: null,
      allTrades: [],
      tradeContributions: [],
      oldTradeContributionsAsString: null,
      tradeContributionIndex: null,
      informationPopup: {
        show: false,
        title: '',
        content: '',
        validate: '',
        params: [],
      },
      listMode: ListMode.TRADE_CONTRIBUTIONS,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    ListMode () {
      return ListMode
    },
    showThreads () {
      return this.listMode === ListMode.THREADS && this.currentContribution
    },
    currentContribution: {
      get () {
        return this.tradeContributionIndex === null ? null : this.tradeContributions[this.tradeContributionIndex]
      },
      async set (contrib) {
        if (this.tradeContributionIndex !== null) {
          if (this.tradeContributionIndex < this.tradeContributions.length) {
            await this.$graphqlMutate(
              getTradeContributionPermissions, { id: contrib.id },
            ).then(response => {
              contrib.permissions = response.tradeContribution.permissions
            })
          }
          const newTradeContributions = this.tradeContributions.slice()
          newTradeContributions[this.tradeContributionIndex] = contrib
          this.tradeContributions = newTradeContributions
        }
      },
    },
  },
  watch: {
    documentVersionId: {
      handler () {
        return this.setData()
      },
      immediate: true,
    },
    lockedMode () {
      return this.setData()
    },
    currentStep () {
      return this.setData()
    },
    tradeContributions (value) {
      const svalue = JSON.stringify(value)
      if (svalue !== this.oldTradeContributionsAsString) {
        this.oldTradeContributionsAsString = svalue
        this.$emit('tradeContributionsChanged', this.tradeContributions)
        if (this.currentStep === STEP_EXTERNAL_CONTRIBUTIONS) {
          this.$emit('previewUpdateRequired')
        }
      }
    },
  },
  methods: {
    openInformationPopup (title, content, validate, params) {
      this.informationPopup.title = title || ''
      this.informationPopup.content = content || ''
      this.informationPopup.validate = validate || ''
      this.informationPopup.params = params || []
      this.informationPopup.show = true
    },
    closeInformationPopup () {
      this.informationPopup.show = false
      this.informationPopup.title = ''
      this.informationPopup.content = ''
      this.informationPopup.validate = ''
      this.informationPopup.params = []
    },
    async setData () {
      const payload = {
        documentVersionId: this.documentVersionId,
        projectId: this.store.project.id,
      }
      await this.$graphqlMutate(allTradeContributions, payload).then(response => {
        this.documentVersion = response.documentVersion
        this.allTrades = response.allTrades
        this.tradeContributions = response.allTradeContributions
        if (this.oldTradeContributionsAsString === null) {
          this.oldTradeContributionsAsString = JSON.stringify(this.tradeContributions)
        }
      })
    },
    onShowTradeContribution (tradeContribution) {
      this.tradeContributionIndex = this.tradeContributions.findIndex(item => item.id === tradeContribution.id)
      this.listMode = ListMode.THREADS
    },
    onCloseContributionPanel () {
      this.listMode = ListMode.TRADE_CONTRIBUTIONS
      this.tradeContributionIndex = null
    },
    updateLocalTradeContribution (remoteTradeContribution) {
      let index = this.tradeContributions.findIndex(item => item.id === remoteTradeContribution.id)
      if (index === -1) {
        index = this.tradeContributions.length
      }
      const newTradeContributions = this.tradeContributions.slice()
      newTradeContributions[index] = remoteTradeContribution
      this.tradeContributions = newTradeContributions
    },
    async createNewTradeContribution (trade) {
      return this.$graphqlMutate(createTradeContribution, {
        documentVersionId: this.documentVersionId,
        tradeId: trade.id,
      }).then(response => {
        this.updateLocalTradeContribution(response.createTradeContribution)
        this.store.changeNotification(
          {
            type: 'positive',
            text: this.$gettext('Changes saved'),
            autoClose: true,
          },
        )
      })
    },
    async onAssignTradeContribution (tradeContribution, reopen) {
      if (reopen) {
        this.openInformationPopup(
          this.$gettext('Re-open and self-assign on a trade contribution'),
          (
            `${tradeContribution.assignedTo.fullName} `
            + this.$gettext('is already assigned to this contribution. '
            + 'Are you sure you want to assign it to yourself instead?')
          ),
          this.reopenAndAssignContribution,
          [tradeContribution],
        )
        return Promise.resolve()
      } else if (tradeContribution.assignedTo === null) {
        return this.assignTradeContribution(tradeContribution)
      } else {
        this.openInformationPopup(
          this.$gettext('Deleting an assignment'),
          (
            `${tradeContribution.assignedTo.fullName} `
            + this.$gettext('is already assigned to this contribution and has submitted it. '
            + 'Are you sure you want to cancel this submission and assign it to yourself instead?')
          ),
          this.assignTradeContribution,
          [tradeContribution],
        )
        return Promise.resolve()
      }
    },
    async assignTradeContribution (tradeContribution) {
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.ASSIGNED,
      }).then(response => {
        this.closeInformationPopup()
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    async onUnassignTradeContribution (tradeContribution) {
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.INITIAL,
      }).then(response => {
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    async onSetTradeContributionAsNotConcerned (tradeContribution) {
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.NOT_APPLICABLE,
      }).then(response => {
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
        this.onCloseContributionPanel()
      })
    },
    async onSetTradeContributionAsApplicable (tradeContribution) {
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.INITIAL,
      }).then(response => {
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
      })
    },
    async onSubmitContribution () {
      // this.currentContribution is guaranteed to be not null
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: this.currentContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.SUBMITTED,
      }).then(response => {
        this.closeInformationPopup()
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
        this.onCloseContributionPanel()
      })
    },
    async reopenAndAssignContribution (tradeContribution) {
      // this.currentContribution is guaranteed to be not null
      // two call should be made to the backend to transition first to INITIAL then to ASSIGNED
      return this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.INITIAL,
      }).then(() => this.$graphqlMutate(updateTradeContributionStatus, {
        id: tradeContribution.id,
        documentVersionId: this.documentVersionId,
        status: CONTRIBUTION_STATUS.ASSIGNED,
      })).then(response => {
        this.closeInformationPopup()
        this.updateLocalTradeContribution(response.updateTradeContributionStatus)
      })
    },
    async onReopenContributionFromThread () {
      // this.currentContribution is guaranteed to be not null
      const currentContribution = this.currentContribution
      this.openInformationPopup(
        this.$gettext('Re-open a trade contribution'),
        (
          this.$gettext('The contribution has already been submitted, do you really want to cancel the submission?')
        ),
        this.reopenAndAssignContribution,
        [currentContribution],
      )
      return Promise.resolve()
    },
    onNextStepButton () {
      if (this.currentStep === STEP_TRADE_CONTRIBUTIONS) {
        this.checkAllTradeContributionsSubmitted()
      } else if (this.currentStep === STEP_EXTERNAL_CONTRIBUTIONS) {
        this.openInformationPopupNextStep()
      }
    },
    checkAllTradeContributionsSubmitted () {
      if (this.tradeContributions.every(
        tc => [CONTRIBUTION_STATUS.NOT_APPLICABLE, CONTRIBUTION_STATUS.SUBMITTED].includes(tc.status),
      )) {
        this.openInformationPopupNextStep()
      } else {
        this.openInformationPopupNextStep(this.$gettext('All applicable trades have not submitted their contribution.'))
      }
    },
    openInformationPopupNextStep (extraMsg) {
      this.openInformationPopup(
        this.$gettext('Transition to next step'),
        (extraMsg ? extraMsg + ' ' : '') + this.$gettext('Are you sure you want to go to the next step?'),
        this.nextStep,
      )
    },
    async nextStep () {
      let query, payload, resultKey
      if ([STEP_TRADE_CONTRIBUTIONS, STEP_EXTERNAL_CONTRIBUTIONS].includes(this.currentStep)) {
        query = finishTradeContributions
        payload = {
          documentVersionId: this.documentVersionId,
        }
        resultKey = 'finishTradeContributions'
      }
      return this.$graphqlMutate(query, payload).then(response => {
        this.closeInformationPopup()
        this.$emit('nextStep', response[resultKey].currentStepTemplate.type)
      }).catch(error => {
        this.closeInformationPopup()
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    onShowThreadDetails () {
      this.$emit('reduceDocumentInformationRequest')
    },
    onCloseThreadDetails () {
      this.$emit('expandDocumentInformationRequest')
    },
  },
}
</script>
<template>
  <section class="trade-contribution-section">
    <div
      v-if="listMode === ListMode.TRADE_CONTRIBUTIONS && documentVersion"
      class="card"
    >
      <TradeContributionsHeader
        :current-step="currentStep"
        :document-version="documentVersion"
        :is-oman-type="isOmanType"
      />
      <TradeContributionsTable
        :current-step="currentStep"
        :locked-mode="lockedMode"
        :trade-contributions="tradeContributions"
        @show-trade-contribution="onShowTradeContribution"
        @assign-trade-contribution="onAssignTradeContribution"
        @unassigned-trade-contribution="onUnassignTradeContribution"
        @set-trade-contribution-as-not-concerned="onSetTradeContributionAsNotConcerned"
        @set-trade-contribution-as-applicable="onSetTradeContributionAsApplicable"
      />
      <TradeContributionsButtons
        v-if="!lockedMode"
        :current-step="currentStep"
        :all-trades="allTrades"
        :document-version="documentVersion"
        :trade-contributions="tradeContributions"
        @new-trade-contribution="createNewTradeContribution"
        @next-step="onNextStepButton"
      />
    </div>
    <div
      v-else-if="showThreads"
      class="card"
    >
      <Threads
        v-model:trade-contribution="currentContribution"
        :current-step="currentStep"
        :locked-mode="lockedMode"
        :document-version-id="documentVersionId"
        :is-oman-type="isOmanType"
        @close-contribution-panel="onCloseContributionPanel"
        @submit-contribution="onSubmitContribution"
        @reopen-contribution="onReopenContributionFromThread"
        @show-thread-details="onShowThreadDetails"
        @close-thread-details="onCloseThreadDetails"
      />
    </div>
    <InformationPopup
      :model-value="informationPopup.show"
      :title="informationPopup.title"
      :content="informationPopup.content"
      :params="informationPopup.params"
      @validate="informationPopup.validate"
      @cancel="closeInformationPopup"
    />
  </section>
</template>
<style lang="scss" scoped>
.trade-contribution-section {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.card {
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}
</style>
