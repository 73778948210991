<script>
export default {
  name: 'SmallInformation',
  props: {
    documentVersion: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      documentTitle: '',
      documentIndex: '',
    }
  },
  computed: {
    computedCurrentStep () {
      if (this.currentStep === 'TradeContributions') {
        return this.$gettext('Trade contribution')
      } else if (this.currentStep === 'ExternalContributions') {
        return this.$gettext('External Contributions')
      } else {
        return this.$gettext('Project Manager Evaluation')
      }
    },
  },
  mounted () {
    this.setData()
  },
  methods: {
    setData () {
      if (this.documentVersion) {
        this.documentTitle = this.documentVersion.title
        this.documentIndex = this.documentVersion.index
      }
    },
    openSharepointDirectory (_event, Window) {
      if (!Window) {
        Window = window
      }
      const documentVersion = this.documentVersion
      Window.open(documentVersion.directoryUrl, '_blank')
    },
  },
}
</script>
<template>
  <section class="card document-info-card">
    <div>
      <v-icon
        size="small"
        color="secondary"
        icon="fas fa-file"
        start
      />
      <strong>{{ `${$gettext('Title')}${$gettext(':')} ` }}</strong>
      <span>{{ documentTitle }}</span>
    </div>
    <div class="full-width">
      <v-icon
        size="small"
        color="secondary"
        icon="fas fa-file"
        start
      />
      <strong>{{ `${$gettext('Index')}${$gettext(':')} ` }}</strong>
      <span>{{ documentIndex }}</span>
    </div>
    <div class="full-width">
      <v-icon
        size="small"
        color="secondary"
        icon="fas fa-user"
        start
      />
      <strong>{{ $gettext(computedCurrentStep) }}</strong>
    </div>
    <v-btn
      size="small"
      variant="text"
      color="secondary"
      prepend-icon="fas fa-folder-open"
      @click="openSharepointDirectory"
    >
      {{ $gettext('Open the Sharepoint folder') }}
    </v-btn>
  </section>
</template>
<style lang="scss" scoped>
.document-info-card {
  flex-direction: row;
  padding: 10px 20px;
  color: $secondary;
  gap: 1rem;
  align-items: center;
}
.document-info-card > div {
  font-size: 0.9rem;
}
.title {
  flex-grow: 1;
  flex-shrink: 1;
}
.full-width {
  flex-grow: 0;
  flex-shrink: 0;
}
strong, span {
  margin-top: 10px;
}
</style>
