<script>
export default {
  name: 'ContributingTradesSection',
  props: {
    activeTrades: {
      type: Array,
      required: true,
    },
    getUsers: {
      type: Function,
      required: true,
    },
    hasDocuments: {
      type: Boolean,
      default: false,
    },
    retrieveData: {
      type: Function,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'toggleTradeDialog',
  ],
  methods: {
    changeTradeData (trade, key) {
      trade[key] = true
      const index = this.trades.findIndex(o => o.id === trade.id)
      this.trades[index] = trade
    },
    toggleTradeDialog (action, trade) {
      this.$emit('toggleTradeDialog', action, trade)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Contributing trades') }}</h1>
    <v-card>
      <v-card-text>
        <div
          v-if="activeTrades.length"
          class="trades-container"
        >
          <div
            v-for="trade in activeTrades"
            :key="trade.name"
            class="row"
          >
            <ChipsAutocomplete
              :name="trade.acronym"
              :init="trade.experts"
              item-value="id"
              :item-display="item => `${item.fullName} (${item.email})`"
              :allow-new-value="false"
              :list="users"
              hide-details
              @user-typing="getUsers"
              @retrieve-data="retrieveData($event, trade, 'experts')"
            />
            <v-btn
              color="secondary"
              variant="text"
              icon="fas fa-pen"
              size="small"
              @click="toggleTradeDialog('edit', trade)"
            />
            <v-btn
              color="secondary"
              variant="text"
              size="small"
              :disabled="hasDocuments && !trade._created"
              icon="fas fa-trash"
              @click="changeTradeData(trade, '_deleted')"
            />
          </div>
        </div>
        <v-btn
          color="secondary"
          prepend-icon="fas fa-plus"
          rounded
          @click="toggleTradeDialog('new')"
        >
          {{ $gettext('Add a contributing trade') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.trades-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
