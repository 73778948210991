<script>
export default {
  name: 'CommentEditImportance',
  props: {
    importanceProp: {
      type: String,
      default: () => '',
    },
    canCreateInternalNotes: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'change',
  ],
  data () {
    return {
      importance: this.importanceProp,
    }
  },
  computed: {
    isInternalImportance () {
      return this.importance === 'INTERNAL'
    },
    internalNoteWarning () {
      return this.$gettext('Warning: this type of comment is for use by'
        + ' the internal team only and will not be included in the approval sheet.')
    },
  },
  methods: {
    change () {
      this.$emit('change', this.importance)
    },
  },
}
</script>
<template>
  <div class="comment-edit-importance">
    <v-radio-group
      v-model="importance"
      hide-details
      color="primary"
      inline
      @update:model-value="change"
    >
      <v-radio
        :label="$gettext('Minor')"
        value="MINOR"
      />
      <v-radio
        :label="$gettext('Major')"
        value="MAJOR"
      />
      <v-radio
        v-if="canCreateInternalNotes"
        :label="$gettext('Internal')"
        value="INTERNAL"
      />
    </v-radio-group>
    <div
      class="internal-note-warning"
    >
      <span
        v-if="isInternalImportance"
      >
        {{ internalNoteWarning }}
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.comment-edit-importance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2%;
}
.internal-note-warning {
  min-height: 2em;
}
</style>
