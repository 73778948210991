<script>
import {
  STEP_TRADE_CONTRIBUTIONS,
  STEP_EXTERNAL_CONTRIBUTIONS,
  STEP_PM_EVALUATION,
  THREAD_IMPORTANCE,
} from '@src/utils/consts'
import CommentImagePopup from '@comp/document/comments/CommentImagePopup.vue'
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

const IMPORTANCE_ORDER = [
  'MAJOR',
  'MINOR',
  'INTERNAL',
]
export default {
  name: 'ThreadsTable',
  components: {
    CommentImagePopup,
    FontAwesomeLayers,
  },
  inject: [
    'isThreadLockToggable',
    'displayThreadLockTitle',
    'getThreadLockIcon',
    'lockThreadToggle',
  ],
  props: {
    threads: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      required: true,
    },
    canCreateThread: {
      type: Boolean,
      required: true,
    },
    showLockButton: {
      type: Boolean,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
    dataProcessing: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: [
    'add',
    'show',
  ],
  data () {
    return {
      showImagePopup: false,
      currentComment: null,
    }
  },
  computed: {
    isInPmEvaluationStep () {
      return this.currentStep === STEP_PM_EVALUATION
    },
    isInTradeContributionsStep () {
      return this.currentStep === STEP_TRADE_CONTRIBUTIONS
    },
    isInExternalContributionsStep () {
      return this.currentStep === STEP_EXTERNAL_CONTRIBUTIONS
    },
    headers () {
      const headers = [
        { title: this.$gettext('Creation index'), key: 'createdAtVersion', sortable: false, width: '8em' },
        { title: this.$gettext('Importance'), key: 'importance', sortable: false, width: '6em' },
        { title: this.$gettext('Extract'), key: 'text', sortable: false, width: 'auto' },
        { title: this.$gettext('Actions'), key: 'actions', sortable: false, width: '140px' },
      ]
      if (this.isInPmEvaluationStep) {
        headers.unshift({ title: this.$gettext('Métier'), key: 'trade', sortable: false, width: '8em' })
      }
      return headers
    },
    orderedThreads () {
      return this.threads.slice().sort(this.threadComparator)
    },
    MAX_CHARS () {
      return 30
    },
    canAdd () {
      return !this.lockedMode && !this.dataProcessing && this.canCreateThread
    },
  },
  methods: {
    threadVersionIndexComparator (th1, th2) {
      if (th1.createdAtVersion.index === th2.createdAtVersion.index) {
        return 0
      } else {
        return th2.createdAtVersion.index - th1.createdAtVersion.index
      }
    },
    threadTradeComparator (th1, th2) {
      if (th1.trade.acronym === th2.trade.acronym) {
        return 0
      } else {
        return th1.trade.acronym.localeCompare(th2.trade.acronym)
      }
    },
    threadImportanceComparator (th1, th2) {
      if (this.isOmanType) {
        const versionBigNumber = 1000000
        const versionRegex = RegExp('(\\d+)', 'g')
        const versionFilter = s => s.replace(versionRegex, n => '' + (1 * n + versionBigNumber))
        const versionList = [th1.importance, th2.importance].map(versionFilter)
        if (versionList[0] === versionList[1]) {
          return 0
        } else {
          return versionList[0] < versionList[1] ? -1 : 1
        }
      } else {
        const th1ImportanceOrder = IMPORTANCE_ORDER.indexOf(th1.importance)
        const th2ImportanceOrder = IMPORTANCE_ORDER.indexOf(th2.importance)
        if (th1ImportanceOrder === th2ImportanceOrder) {
          return 0
        } else {
          return th1ImportanceOrder - th2ImportanceOrder
        }
      }
    },
    threadComparator (th1, th2) {
      // 1. active, inactive
      // 2. Importance
      // 3. Trade (alpha du code)
      // 4. Creation index (most recent is first)
      // 5. Sequence
      const th1Active = this.isThreadLockToggable(th1)
      const th2Active = this.isThreadLockToggable(th2)
      if (th1Active && !th2Active) {
        return -1
      } else if (!th1Active && th2Active) {
        return 1
      }
      const importanceComparison = this.threadImportanceComparator(th1, th2)
      if (importanceComparison !== 0) {
        return importanceComparison
      }
      const tradeComparison = this.threadTradeComparator(th1, th2)
      if (tradeComparison !== 0) {
        return tradeComparison
      }
      const versionIndexComparison = this.threadVersionIndexComparator(th1, th2)
      if (versionIndexComparison !== 0) {
        return versionIndexComparison
      }
      return th1.sequence - th2.sequence
    },
    displayCreatedAtVersion (thread) {
      return thread.createdAtVersion.index
    },
    displayImportance (thread) {
      return this.isOmanType ? thread.importance : this.$gettext(THREAD_IMPORTANCE[thread.importance])
    },
    sortCommentsDescendingOrder (c1, c2) {
      if (c1.documentVersion.index > c2.documentVersion.index) {
        return -1
      } else if (c1.documentVersion.index < c2.documentVersion.index) {
        return 1
      } else {
        return 0
      }
    },
    getLastComment (thread) {
      let comments = []
      if (
        [STEP_TRADE_CONTRIBUTIONS, STEP_EXTERNAL_CONTRIBUTIONS].includes(this.currentStep)
        && thread.initialComments.length
      ) {
        comments = [thread.initialComments[0]]
      } else {
        comments = thread.allComments.slice()
      }
      comments.sort(this.sortCommentsDescendingOrder)
      return comments[0]
    },
    getLastCommentTextLines (thread) {
      const lastComment = this.getLastComment(thread)
      const sep = '</p>'
      const lines = lastComment.text.split(sep).filter(line => line).map(line => line + sep)
      // garanteed to be a non-empty arry
      return lines.length ? lines : ['']
    },
    displayLastCommentFirstLineFullText (thread) {
      const lines = this.getLastCommentTextLines(thread)
      const htmlText = new DOMParser().parseFromString(lines[0], 'text/html')
      const firstLine = htmlText.body.textContent
      if (lines.length >= 2) {
        return `${firstLine}[…]`
      } else {
        return firstLine
      }
    },
    displayLastCommentText (thread) {
      const lines = this.getLastCommentTextLines(thread)
      const htmlText = new DOMParser().parseFromString(lines[0], 'text/html')
      const firstLine = htmlText.body.textContent
      if (firstLine.length > this.MAX_CHARS) {
        return `${firstLine.substring(0, this.MAX_CHARS)}…`
      } else {
        return firstLine
      }
    },
    displayLastCommentImageLink (thread) {
      return this.getLastComment(thread).imageUrl
    },
    showImage (comment) {
      this.currentComment = comment
      this.showImagePopup = true
    },
    closeImage () {
      this.showImagePopup = false
      this.currentComment = null
    },
    add () {
      this.$emit('add')
    },
    displayShowThreadTitle (thread) {
      let title = this.$gettext('See the comment thread')
      title += ' (' + (thread.closed ? this.$gettext('Closed') : this.$gettext('Active')) + ')'
      return title
    },
    getThreadStatusSubIcon (thread) {
      return thread.closed ? 'fas fa-check' : 'fas fa-ellipsis-h'
    },
    canShow () {
      return !this.dataProcessing
    },
    show (thread) {
      this.$emit('show', thread)
    },
    isThreadLockEnabled (thread) {
      if (this.dataProcessing) {
        return false
      } else {
        return this.isThreadLockToggable(thread)
      }
    },
  },
}
</script>
<template>
  <v-data-table-virtual
    :headers="headers"
    :items="orderedThreads"
    fixed-header
    class="threads-table"
  >
    <template v-slot:header.actions>
      {{ $gettext('Actions') }}
      <v-tooltip
        location="end"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-fab
            v-if="canAdd"
            size="small"
            color="primary"
            class="add-btn"
            icon="fas fa-plus"
            v-bind="props"
            @click="add"
          />
        </template>
        {{ $gettext('Create a new comment thread') }}
      </v-tooltip>
    </template>
    <template
      v-if="isInPmEvaluationStep"
      #item.trade="{ item: thread }"
    >
      <span :title="thread.trade.name">{{ thread.trade.acronym }}</span>
    </template>
    <template #item.createdAtVersion="{ item: thread }">
      {{ displayCreatedAtVersion(thread) }}
    </template>
    <template #item.importance="{ item: thread }">
      {{ displayImportance(thread) }}
    </template>
    <template #item.text="{ item: thread }">
      <!-- eslint-disable vue/no-v-html -->
      <div
        :title="displayLastCommentFirstLineFullText(thread)"
        v-html="displayLastCommentText(thread)"
      />
    </template>
    <template #item.imageUrl="{ item: thread }">
      <v-icon
        v-if="displayLastCommentImageLink(thread)"
        size="small"
        color="secondary"
        :title="$gettext('Show illustration')"
        icon="fas fa-paperclip"
        @click="showImage(getLastComment(thread))"
      />
    </template>
    <template #item.actions="{ item: thread }">
      <div class="row">
        <v-btn
          variant="text"
          size="small"
          icon
        >
          <v-icon>
            <font-awesome-layers
              class="fa-fw"
              :title="displayShowThreadTitle(thread)"
              :disabled="!canShow()"
              @click="show(thread)"
            >
              <font-awesome-icon
                icon="fas fa-comments"
                color="secondary"
                transform="left-2"
              />
              <font-awesome-icon
                :icon="getThreadStatusSubIcon(thread)"
                transform="shrink-6 up-7 right-8"
                color="darkgreen"
              />
            </font-awesome-layers>
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showLockButton"
          size="small"
          variant="text"
          color="secondary"
          :disabled="!isThreadLockEnabled(thread)"
          :title="displayThreadLockTitle(thread)"
          :icon="getThreadLockIcon(thread)"
          @click="lockThreadToggle(thread)"
        />
      </div>
    </template>
  </v-data-table-virtual>
  <CommentImagePopup
    v-if="showImagePopup && currentComment"
    :comment="currentComment"
    @close="closeImage"
  />
</template>
<style lang="scss" scoped>
.threads-table {
  overflow-y: auto;
  flex-grow: 1;
}
.add-btn {
  top: 20px;
  left: 50px;
  justify-content: flex-end;
  z-index: 20; // above table header and table
}
</style>
