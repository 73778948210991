<script>
export default {
  name: 'ProjectTeamSection',
  props: {
    activeContracts: {
      type: Array,
      required: true,
    },
    managers: {
      type: Array,
      required: true,
    },
    docControllers: {
      type: Array,
      required: true,
    },
    getUsers: {
      type: Function,
      required: true,
    },
    retrieveData: {
      type: Function,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Project team') }}</h1>
    <v-card>
      <v-card-text>
        <ChipsAutocomplete
          :name="$gettext('Project manager')"
          :init="managers"
          item-value="id"
          :item-display="item => `${item.fullName} (${item.email})`"
          :allow-new-value="false"
          :list="users"
          hide-details
          @user-typing="getUsers"
          @retrieve-data="retrieveData($event, 'managers')"
        />
        <ChipsAutocomplete
          :name="$gettext('Doc control')"
          :init="docControllers"
          item-value="id"
          :item-display="item => `${item.fullName} (${item.email})`"
          :allow-new-value="false"
          :list="users"
          hide-details
          @user-typing="getUsers"
          @retrieve-data="retrieveData($event, 'docControllers')"
        />
        <ChipsAutocomplete
          v-for="contract in activeContracts"
          :key="contract.id"
          :name="`${$gettext('Contract lead')} ${contract.name}`"
          :init="contract.managers"
          item-value="id"
          :item-display="item => `${item.fullName} (${item.email})`"
          :allow-new-value="false"
          :list="users"
          hide-details
          @user-typing="getUsers"
          @retrieve-data="retrieveData($event, contract, 'managers')"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.v-card-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
