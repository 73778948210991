<script>
import CodificationSeparatorSelect from './CodificationSeparatorSelect.vue'
import ApprovalSheetName from './ApprovalSheetName.vue'

export default {
  name: 'CodificationSection',
  components: {
    ApprovalSheetName,
    CodificationSeparatorSelect,
  },
  props: {
    unicityList: {
      type: Array,
      required: true,
    },
    codificationList: {
      type: Array,
      required: true,
    },
    indexList: {
      type: Array,
      required: true,
    },
    titleList: {
      type: Array,
      required: true,
    },
    activeMetadata: {
      type: Array,
      required: true,
    },
    separatorProp: {
      type: String,
      required: true,
    },
    approvalSheetSuffix: {
      type: String,
      required: true,
    },
    approvalSheetPrefix: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'retrieveData',
    'changeSeparator',
    'validate',
    'changeApprovalSheetSuffix',
    'changeApprovalSheetPrefix',
  ],
  data () {
    return {
      separator: this.separatorProp,
    }
  },
  computed: {
    unicityChipsList () {
      return this.activeMetadata.concat({ name: this.$gettext('Contract') })
    },
  },
  watch: {
    codificationList: {
      handler () {
        this.$emit('validate')
      },
    },
    unicityList: {
      handler () {
        this.$emit('validate')
      },
    },
  },
  methods: {
    isValidIndex (index) {
      const unicityValues = this.unicityList.map(o => o.name)
      const codificationValues = this.codificationList.map(o => o.name)
      if (index) {
        const indexValues = index.map(o => o.name)
        const unicityCheck = unicityValues.filter(o => indexValues.includes(o))
        const codificationCheck = codificationValues.filter(o => indexValues.includes(o))
        return (!unicityCheck.length && !codificationCheck.length)
          || this.$gettext('A metadatum used in the index is also in the unicity or the codification')
      } else {
        return true
      }
    },
    onChangeApprovalSheetSuffix (suffix) {
      this.$emit('changeApprovalSheetSuffix', suffix)
    },
    onChangeApprovalSheetPrefix (prefix) {
      this.$emit('changeApprovalSheetPrefix', prefix)
    },
    getNameFromItem (item) {
      return item.name
    },
    onChangeSeparator (separator) {
      this.separator = separator
      this.$emit('changeSeparator', this.separator)
    },
    onRetrieveData ($event, key) {
      this.$emit('retrieveData', $event, key)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Codification') }}</h1>
    <v-card>
      <v-card-text>
        <CodificationSeparatorSelect
          v-model:current-separator="separator"
          :disabled="disabled"
          @update-current-separator="onChangeSeparator"
        />
        <ChipsAutocomplete
          :name="$gettext('Fields making up the documents\' unicity (order counts)')"
          :list="unicityChipsList"
          :locked-value="$gettext('Contract')"
          item-value="name"
          :item-display="getNameFromItem"
          :allow-new-value="false"
          :init="unicityList"
          :disabled="disabled"
          @retrieve-data="onRetrieveData($event, 'unicityList')"
        />
        <ChipsAutocomplete
          :name="$gettext('Fields making up the codification (order counts)')"
          :list="activeMetadata"
          item-value="name"
          :item-display="getNameFromItem"
          :allow-new-value="false"
          :init="codificationList"
          :disabled="disabled"
          @retrieve-data="onRetrieveData($event, 'codificationList')"
        />
        <ChipsAutocomplete
          :name="$gettext('Fields making up the index (order counts)')"
          :list="activeMetadata"
          item-value="name"
          :item-display="getNameFromItem"
          :allow-new-value="false"
          :init="indexList"
          :disabled="disabled"
          :rules="[isValidIndex]"
          @retrieve-data="onRetrieveData($event, 'indexList')"
        />
        <ChipsAutocomplete
          :name="$gettext('Fields making up the title (order counts)')"
          :list="activeMetadata"
          item-value="name"
          :item-display="getNameFromItem"
          :allow-new-value="false"
          :init="titleList"
          :disabled="disabled"
          @retrieve-data="onRetrieveData($event, 'titleList')"
        />
      </v-card-text>
    </v-card>
    <ApprovalSheetName
      :approval-sheet-prefix="approvalSheetPrefix"
      :approval-sheet-suffix="approvalSheetSuffix"
      @change-approval-sheet-prefix="onChangeApprovalSheetPrefix"
      @change-approval-sheet-suffix="onChangeApprovalSheetSuffix"
    />
  </div>
</template>
