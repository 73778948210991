<script>
import moment from 'moment'
import { WORKFLOW_STEPS } from '@src/utils/consts'
import { deleteDocumentVersion } from './queries'
import { projectRoute } from '@src/router'
import { allInternalNotesForDocumentVersion } from '@comp/document/tradeContributions/queries'
import InternalNotesPopup from '@comp/document/InternalNotesPopup.vue'
import InformationPopup from '@comp/utils/InformationPopup.vue'
import { useIndexStore } from '@src/store/index.js'
const $gettext = s => s // to force vue-i18n-extract to see translations

export default {
  name: 'Information',
  components: {
    InternalNotesPopup,
    InformationPopup,
  },
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    currentStep: {
      type: String,
      default: () => '',
    },
    documentVersion: {
      type: Object,
      default: () => {},
    },
    historicDocumentVersion: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      versionInfo: {
        'Title': '',
        'Index': '',
        'Codification': '',
        'Date received': '',
        'Contract code': '',
        'Contract title': '',
      },
      showInternalNotes: false,
      threads: [],
      deletePopup: {
        open: false,
        title: '',
        loading: false,
        showExtraButtons: false,
        content: '',
        validateParams: [],
        extraButtons: [],
        cancel: this.deleteVersionPopupClose,
      },
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    numberOfThreadsSupToZero () {
      return this.threads.length > 0
    },
    documentVersionOriginGedUrl () {
      return !!this.documentVersion.originGedUrl
    },
    infoLeft () {
      return [$gettext('Title'), $gettext('Index'), $gettext('Codification'), $gettext('Date received')]
    },
    infoContract () {
      return [$gettext('Contract code'), $gettext('Contract title')]
    },
    showInternalNotesButton () {
      // Don’t show the button before trade contributions step
      return WORKFLOW_STEPS[this.currentStep].id >= 2
    },
    isDocControl () {
      const project = this.store.project
      const user = this.store.user
      return !!(user && project.docControllers.filter(dc => dc.id === user.id).length)
    },
  },
  watch: {
    documentVersion: {
      handler () {
        this.setData()
      },
      immediate: true,
    },
    lockedMode () {
      this.setData()
    },
  },
  async mounted () {
    await this.getData()
  },
  methods: {
    async getData () {
      return this.$graphqlQuery(allInternalNotesForDocumentVersion, {
        documentId: this.documentVersion.document.id,
        documentVersionId: this.documentVersion.id,
        documentVersionSequence: this.documentVersion.sequence,
      }).then(response => {
        this.threads = response.document.threads
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    openDoc (Window) {
      Window = Window || window
      Window.open(this.documentVersion.originGedUrl, '_blank')
    },
    setData () {
      const documentVersion = this.lockedMode ? this.historicDocumentVersion : this.documentVersion
      if (documentVersion) {
        this.versionInfo = {
          ...this.versionInfo,
          'Title': documentVersion.title,
          'Index': documentVersion.index,
          'Codification': documentVersion.codification,
          'Date received': this.displayDate(documentVersion.workflowStartDatetime),
          'Contract code': documentVersion.document.contract.code,
          'Contract title': documentVersion.document.contract.name,
        }
      }
    },
    displayDate (date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
    openSharepointDirectory (_event, Window) {
      if (!Window) {
        Window = window
      }
      const documentVersion = this.lockedMode ? this.historicDocumentVersion : this.documentVersion
      Window.open(documentVersion.directoryUrl, '_blank')
    },
    deleteVersionPopup () {
      this.deletePopup.title = this.$gettext('Document version deletion')
      this.deletePopup.content = this.$gettext('Do you really want to delete this document version?')
      this.deletePopup.validate = this.deleteVersion
      this.deletePopup.cancel = this.deleteVersionPopupClose
      this.deletePopup.validateParams = []
      this.deletePopup.showExtraButtons = false
      this.deletePopup.extraButtons = [
        {
          text: this.$gettext('Close'),
          icon: 'fas fa-check',
          emit: 'cancel',
        },
      ]
      this.deletePopup.open = true
    },
    deleteVersion () {
      this.deletePopup.loading = true
      const documentVersion = this.lockedMode ? this.historicDocumentVersion : this.documentVersion
      return this.$graphqlMutate(deleteDocumentVersion, {
        documentVersionId: documentVersion.id,
      }).then(() => {
        this.deletePopup.content = this.$gettext('Version deleted')
        this.deletePopup.cancel = this.deleteVersionRedirect
        this.deletePopup.showExtraButtons = true
        this.deletePopup.loading = false
      }).catch(error => {
        const spLink = documentVersion.directoryUrl
        this.deletePopup.content = (
          this.$gettext('An error occurred.')
          + '\n'
          + this.$gettext('Direct sharepoint link:')
          + '\n'
          + spLink
        )
        this.deletePopup.showExtraButtons = true
        this.deletePopup.loading = false
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    deleteVersionRedirect () {
      this.deleteVersionPopupClose()
      const project = this.store.project
      this.$router.push({
        name: projectRoute.name,
        params: {
          id: project.id,
        },
      })
    },
    deleteVersionPopupClose () {
      this.deletePopup.open = false
      this.deletePopup.showExtraButtons = false
    },
    toggleShowInternalNotes () {
      this.showInternalNotes = !this.showInternalNotes
    },
    onCommentsPopupClose () {
      this.showInternalNotes = false
    },
  },
}
</script>
<template>
  <section
    v-if="documentVersion"
    class="card info-panel"
  >
    <div class="info-column">
      <div class="info-section">
        <div class="info-section-title">
          {{ $gettext('Document') }}
        </div>
        <div class="info-section-content">
          <span
            v-for="item in infoLeft"
            :key="item"
            :class="item == 'Date received' ? 'hide-small-screen' : ''"
          >
            <strong>{{ `${$gettext(item)}${$gettext(':')} ` }}</strong>
            <span>{{ versionInfo[item] }}</span>
          </span>
        </div>
        <div class="info-section-actions">
          <v-btn
            :disabled="!showInternalNotesButton || !numberOfThreadsSupToZero"
            variant="tonal"
            size="small"
            color="secondary"
            prepend-icon="fas fa-sticky-note"
            @click="toggleShowInternalNotes"
          >
            {{ $gettext('Show internal notes') }}
            <template v-slot:append>
              <v-badge
                :model-value="showInternalNotesButton && numberOfThreadsSupToZero"
                color="primary"
                :content="threads.length"
                floating
              />
            </template>
          </v-btn>
          <v-btn
            v-if="isDocControl"
            size="small"
            variant="text"
            color="secondary"
            prepend-icon="fas fa-trash"
            @click.stop.prevent="deleteVersionPopup"
          >
            {{ $gettext('Delete this version') }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="info-column">
      <div class="info-section">
        <div class="info-section-title">
          {{ $gettext('Contract') }}
        </div>
        <div class="info-section-content">
          <span
            v-for="item in infoContract"
            :key="item"
          >
            <strong>{{ `${$gettext(item)}${$gettext(':')} ` }}</strong>
            <span>{{ versionInfo[item] }}</span>
          </span>
        </div>
      </div>
      <div class="info-section">
        <div class="info-section-title">
          {{ $gettext('Folder') }}
        </div>
        <div class="info-section-actions">
          <v-btn
            variant="text"
            size="small"
            color="secondary"
            prepend-icon="fas fa-folder-open"
            @click="openSharepointDirectory"
          >
            {{ $gettext('Open the Sharepoint folder') }}
          </v-btn>
          <v-btn
            v-if="documentVersionOriginGedUrl"
            variant="text"
            size="small"
            color="secondary"
            prepend-icon="fas fa-file-alt"
            @click.stop.prevent="openDoc()"
          >
            {{ $gettext('Original DMS link') }}
          </v-btn>
        </div>
      </div>
    </div>
    <InternalNotesPopup
      v-model:popup-shown-prop="showInternalNotes"
      :document-version="documentVersion"
      :all-threads="threads"
      @close="onCommentsPopupClose"
    />
    <InformationPopup
      :model="deletePopup.open"
      :title="deletePopup.title"
      :loading="deletePopup.loading"
      :show-extra-buttons="deletePopup.showExtraButtons"
      :content="deletePopup.content"
      :params="deletePopup.validateParams"
      :extra-buttons="deletePopup.extraButtons"
      @cancel="deletePopup.cancel"
      @validate="deleteVersion"
    />
  </section>
</template>
<style lang="scss" scoped>
.folder-actions {
  display: flex;
  flex-direction: column
}
.info-panel {
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  gap: 1rem;
  font-size: 0.9rem;
}
.info-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.info-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.info-section-title {
  background-color: $grey-light;
  padding: 5px 10px;
  font-weight: 500;
}
.info-section-content {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.info-section-actions {
  padding: 0 5px;
}

@media (width <= 1200px), (height <= 650px) {
  .info-section-title {
    display: none;
  }
}
</style>
