<script>
import { documentRoute } from '@src/router'

export default {
  name: 'IndexChoicePopup',
  props: {
    documentIndexes: {
      type: Array,
      required: true,
    },
    indexChoicePopup: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'closePopup',
  ],
  data () {
    return {
      indexChoiceId: null,
    }
  },
  computed: {
    popupIsOpen () {
      return this.indexChoicePopup
    },
  },
  methods: {
    goToIndex () {
      this.$router.push({
        name: documentRoute.name,
        params: { id: this.indexChoiceId },
      })
    },
    closePopup () {
      this.$emit('closePopup')
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="popupIsOpen"
    persistent
    width="30%"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $gettext("Select the index to view") }}
      </v-card-title>
      <v-card-text>
        {{ $gettext('Which index do you want to view?') }}
        <v-select
          v-model="indexChoiceId"
          :items="documentIndexes"
          item-value="id"
          item-title="index"
          :label="$gettext('Index')"
          color="secondary"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="lightgrey"
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="closePopup"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          prepend-icon="fas fa-save"
          @click="goToIndex"
        >
          {{ $gettext('Submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
