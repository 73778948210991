<script>
export default {
  name: 'ThreadsButtons',
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    canSubmit: {
      type: Boolean,
      required: true,
    },
    canReopen: {
      type: Boolean,
      required: true,
    },
    dataProcessing: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'closeContributionPanel',
    'submitContribution',
    'reopenContribution',
  ],
  computed: {
    reopenButtonShown () {
      return this.canReopen
    },
    isActive () {
      return !this.dataProcessing
    },
    canSubmitConribution () {
      return this.isActive && this.canSubmit
    },
  },
  methods: {
    close () {
      this.$emit('closeContributionPanel')
    },
    submitContribution () {
      this.$emit('submitContribution')
    },
    reopenContribution () {
      this.$emit('reopenContribution')
    },
  },
}
</script>
<template>
  <div class="document-step-btn-container">
    <v-btn
      color="lightgrey"
      prepend-icon="fa fa-arrow-left"
      @click="close"
    >
      {{ $gettext('Back') }}
    </v-btn>
    <v-btn
      v-if="reopenButtonShown"
      color="secondary"
      :disabled="!isActive"
      prepend-icon="fas fa-pen"
      @click="reopenContribution"
    >
      {{ $gettext('Edit the trade contribution') }}
    </v-btn>
    <v-btn
      v-else
      color="primary"
      :disabled="!canSubmitConribution"
      prepend-icon="fas fa-check"
      @click="submitContribution"
    >
      {{ $gettext('Submit the trade contribution') }}
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.document-step-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.left-column {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.right-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
