<script>
export default {
  name: 'ContributingTradeDialog',
  props: {
    trade: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  emits: [
    'addNewTrade',
    'closeTradeDialog',
  ],
  data () {
    return {
      currentTrade: null,
      newTradeName: '',
      newTradeAcronym: '',
    }
  },
  computed: {
    tradeDialog () {
      return true
    },
    oneValueIsEmpty () {
      return this.newTradeName === '' || this.newTradeAcronym === ''
    },
  },
  mounted () {
    this.openTradeDialog()
  },
  methods: {
    editTrade () {
      if (this.currentTrade) {
        this.currentTrade.acronym = this.newTradeAcronym
        this.currentTrade.name = this.newTradeName
        this.currentTrade._updated = true
      } else {
        this.$emit('addNewTrade', {
          id: Math.random().toString(36).substr(2, 9),
          acronym: this.newTradeAcronym,
          name: this.newTradeName,
          experts: [],
          _created: true,
        })
      }
      this.closeTradeDialog()
    },
    resetCurrentTrade () {
      this.currentTrade = null
      this.newTradeName = ''
      this.newTradeAcronym = ''
    },
    openTradeDialog () {
      if (this.trade) {
        this.currentTrade = this.trade
        this.newTradeName = this.trade.name
        this.newTradeAcronym = this.trade.acronym
      } else {
        this.resetCurrentTrade()
      }
    },
    closeTradeDialog () {
      this.$emit('closeTradeDialog')
    },
  },
}
</script>
<template>
  <v-dialog
    v-model="tradeDialog"
    persistent
    width="30%"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $gettext('Add a new contributing trade') }}
      </v-card-title>
      <v-card-text>
        <div>{{ $gettext('What name do you want to give to the new trade?') }}</div>
        <v-text-field
          v-model="newTradeName"
          color="secondary"
          :label="$gettext('Trade full name')"
        />
        <v-text-field
          v-model="newTradeAcronym"
          color="secondary"
          :label="$gettext('Trade code')"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="lightgrey"
          variant="elevated"
          prepend-icon="fas fa-times"
          @click="closeTradeDialog"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :disabled="oneValueIsEmpty"
          prepend-icon="fas fa-save"
          @click="editTrade"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.v-card {
  padding: 0 !important;
}
</style>
