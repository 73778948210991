<script>
import { projectSettingsRoute } from '@src/router/index'
import { useIndexStore } from '@src/store/index'
import InformationPopup from '@comp/utils/InformationPopup.vue'

export default {
  name: 'ProjectCard',
  components: {
    InformationPopup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'deleteProject',
    'enterProject',
  ],
  data () {
    return {
      project: {
        id: null,
        name: '',
        permissions: {},
        isPublished: false,
      },
      showDeleteDialog: false,
    }
  },
  computed: {
    getBtnColor () {
      return this.project.isPublished ? 'secondary' : 'white'
    },
    canIConfigure () {
      return this.project.permissions.canIConfigure
    },
    isCardDisabled () {
      return !this.project.isPublished ? 'card-disabled' : ''
    },
    deleteDialogData () {
      return {
        text: `${this.$gettext('Are you sure you want to delete the project')} `
        + `${this.project.name} ? ${this.$gettext('This action is irreversible')}`,
        buttons: [
          {
            label: this.$gettext('Cancel'),
            icon: 'fas fa-times',
            event: 'cancel',
          },
          {
            label: this.$gettext('Delete'),
            icon: 'fas fa-trash',
            color: 'red',
            event: 'validate',
          },
        ],
      }
    },
  },
  mounted () {
    this.setData()
  },
  methods: {
    setData () {
      this.project = this.item
    },
    async goToProject () {
      this.$emit('enterProject', this.project)
    },
    editProject () {
      const store = useIndexStore()
      store.changeProject(this.project)
      this.$router.push({
        name: projectSettingsRoute.name,
        params: { id: this.project.id },
      })
    },
    deleteProject () {
      this.showDeleteDialog = false
      this.$emit('deleteProject', this.project)
    },
    toggleDeleteDialog () {
      this.showDeleteDialog = !this.showDeleteDialog
    },
  },
}
</script>
<template>
  <div
    v-ripple
    class="card project-card"
    :class="isCardDisabled"
    @click="goToProject()"
  >
    <div class="project-card-title">
      {{ project.name }}
    </div>
    <div
      v-if="!project.isPublished"
      class="project-not-configured"
    >
      {{ $gettext('Unpublished project') }}
      <v-icon
        class="cogs-icon"
        color="primary"
        size="50"
        icon="fas fa-cogs"
      />
    </div>
    <div class="action-buttons">
      <v-tooltip
        location="start"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="canIConfigure"
            :color="getBtnColor"
            icon="fas fa-pen"
            v-bind="props"
            variant="text"
            size="small"
            @click.stop.prevent="editProject(project)"
          />
        </template>
        {{ $gettext('Change project parameters') }}
      </v-tooltip>
      <v-tooltip
        location="start"
        color="secondary"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            :color="getBtnColor"
            icon="fas fa-trash"
            v-bind="props"
            variant="text"
            size="small"
            @click.stop.prevent="toggleDeleteDialog()"
          />
        </template>
        {{ $gettext('Delete') }}
      </v-tooltip>
    </div>
    <InformationPopup
      v-model="showDeleteDialog"
      :title="`${$gettext('Delete project:')} ${project.name}`"
      :buttons="deleteDialogData.buttons"
      @cancel="toggleDeleteDialog"
      @validate="deleteProject"
    >
      {{ deleteDialogData.text }}
    </InformationPopup>
  </div>
</template>
<style lang="scss" scoped>
.project-card {
  transition: 0.2s;
  flex-direction: row;
  align-items: baseline;
  display: flex;
  padding: 5px 20px;
}
.project-card:hover {
  cursor: pointer;
  box-shadow: 0 5px 10px rgba($black, 15%);
  background-color: $primary;
}
.project-card-title {
  flex: 1 1 0;
  font-weight: bold;
  font-size: 1.2rem;
}
.project-not-configured {
  flex: 1 1 0;
  font-weight: bold;
}
.card-disabled {
  background-color: $secondary;
  color: $white !important;
}
.card-disabled:hover {
  cursor: default;
  background-color: $secondary;
}
.cogs-icon {
  opacity: 0.2;
}
.action-buttons {
  display: flex;
  gap: 1ch;
}
</style>
