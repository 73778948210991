<script>
import { STEP_EXTERNAL_CONTRIBUTIONS } from '@src/utils/consts'
import CommentCompanyResponse from './CommentCompanyResponse.vue'
import CommentEditImportance from '@comp/document/comments/CommentEditImportance.vue'
import CommentEditImportanceOmanType from '@comp/document/comments/CommentEditImportanceOmanType.vue'
import TextEditor from '@comp/document/comments/TextEditor.vue'
import ImageEditor from '@comp/document/comments/ImageEditor.vue'

export const MODE = Object.freeze({
  show: 'show',
  edit: 'edit',
})

export default {
  name: 'CommentEditable',
  components: {
    CommentCompanyResponse,
    CommentEditImportance,
    CommentEditImportanceOmanType,
    TextEditor,
    ImageEditor,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    isFirstComment: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: () => true,
    },
    isEditionMode: {
      type: Boolean,
      default: () => false,
    },
    currentStep: {
      type: String,
      default: () => '',
    },
    showButtons: {
      type: Boolean,
      default: () => true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'showImage',
    'startEdit',
    'cancelEdit',
    'saveEdit',
    'delete',
  ],
  data () {
    return {
      mode: MODE.show,
      canCreateInternalNotes: true,
      importance: '',
      newText: '',
      newImage: {
        url: '',
        file: null,
      },
      errorMsg: [],
    }
  },
  computed: {
    MODE: () => MODE,
    canEdit () {
      return this.isEditable && this.comment.permissions.canEdit
    },
    canDelete () {
      return this.isEditable && this.comment.permissions.canDelete
    },
    isExternalContributionsStep () {
      return this.currentStep === STEP_EXTERNAL_CONTRIBUTIONS
    },
    externalCommentWarning () {
      // eslint-disable-next-line max-len
      return this.$gettext('Warning: comments written at this step won\'t be reused in next versions of the document.')
    },
  },
  watch: {
    isEditionMode: {
      handler (value) {
        if (value) {
          this.edit()
        } else {
          this.show()
        }
      },
      immediate: true,
    },
    isFirstComment: {
      handler () {
        if (this.isFirstComment) {
          this.edit()
        }
      },
      immediate: true,
    },
  },
  methods: {
    showImage () {
      this.$emit('showImage', this.comment)
    },
    edit () {
      this.importance = this.isFirstComment ? '' : null
      this.newText = this.comment.text
      this.newImage = {
        url: this.comment.imageUrl,
        file: null,
      }
      this.errorMsg = []
      this.mode = MODE.edit
      this.$emit(
        'startEdit',
        this.comment,
        this.onCancelClick,
        this.onSaveClick,
        this.isSaveEnabled,
      )
    },
    show () {
      this.newText = ''
      this.newImage.url = ''
      this.mode = MODE.show
    },
    isSaveEnabled () {
      return true
    },
    onCancelClick () {
      this.mode = MODE.show
      this.$emit('cancelEdit', this.comment)
    },
    validate () {
      this.errorMsg = []
      if (this.isFirstComment && !this.importance) {
        this.errorMsg.push(this.$gettext('The comment importance has not been filled'))
      }
      if (!this.newText.length) {
        this.errorMsg.push(this.$gettext('The text comment is missing'))
      }
      if (this.isOmanType && this.isFirstComment && this.importance.length > 5) {
        this.errorMsg.push(this.$gettext('5 characters maximum'))
      }
      return this.errorMsg.length === 0
    },
    onSaveClick () {
      if (this.validate()) {
        const oldComment = { ...this.comment }
        const newComment = { ...this.comment }
        newComment.text = this.newText
        newComment.imageUrl = this.newImage.url
        newComment.imageFile = this.newImage.file
        this.$emit('saveEdit', newComment, oldComment, this.importance)
        this.mode = MODE.show
      }
    },
    onDelete () {
      this.$emit('delete', this.comment)
    },
    onImportanceChange (importance) {
      this.importance = importance
    },
    onTextChange (text) {
      this.newText = text
    },
    onImageChange (image) {
      this.newImage.url = image.url
      this.newImage.file = image.file
    },
  },
}
</script>
<template>
  <div v-if="!isEditable || mode === MODE.show">
    <CommentCompanyResponse
      v-if="comment.__typename === 'CompanyResponse'"
      :comment="comment"
    />
    <v-card
      v-else
      class="editable-comment"
    >
      <v-card-title class="title">
        {{ $gettext("Comment created at index ") }} {{ comment.documentVersion.index }}
      </v-card-title>
      <v-card-text class="text">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="comment.text" />
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          v-if="comment.imageUrl"
          size="small"
          color="secondary"
          variant="elevated"
          prepend-icon="fas fa-paperclip"
          @click="showImage"
        >
          {{ $gettext('Show illustration') }}
        </v-btn>
        <v-tooltip
          location="end"
          color="secondary"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-if="canEdit"
              color="secondary"
              variant="text"
              size="small"
              v-bind="props"
              icon="fas fa-pen"
              @click="edit"
            />
          </template>
          {{ $gettext('Edit') }}
        </v-tooltip>
        <v-tooltip
          location="end"
          color="secondary"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-if="canDelete"
              color="secondary"
              variant="text"
              size="small"
              v-bind="props"
              icon="fas fa-trash"
              @click="onDelete"
            />
          </template>
          {{ $gettext('Delete') }}
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
  <div
    v-else
    class="flex-editable"
  >
    <CommentEditImportance
      v-if="isFirstComment && !isOmanType"
      :importance-prop="importance"
      :can-create-internal-notes="canCreateInternalNotes"
      @change="onImportanceChange"
    />
    <CommentEditImportanceOmanType
      v-else-if="isFirstComment && isOmanType"
      :importance-prop="importance"
      @change="onImportanceChange"
    />
    <div
      v-if="isExternalContributionsStep"
      class="warning-message-section warning-message"
    >
      <v-icon
        size="small"
        class="warning-message"
        icon="fas fa-exclamation-circle"
      />
      {{ externalCommentWarning }}
    </div>
    <div class="fake-card">
      <div class="text-and-image">
        <TextEditor
          class="text-editor"
          :text-prop="newText"
          @change="onTextChange"
        />
        <ImageEditor
          class="image-editor"
          :image="newImage"
          @change="onImageChange"
        />
      </div>
      <div
        v-if="showButtons"
        class="comment-edit-buttons"
      >
        <v-btn
          color="lightgrey"
          prepend-icon="fas fa-times"
          @click="onCancelClick"
        >
          {{ $gettext('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isSaveEnabled"
          prepend-icon="fas fa-save"
          @click="onSaveClick"
        >
          {{ $gettext('Save') }}
        </v-btn>
      </div>
    </div>
    <ul
      v-for="(item, i) in errorMsg"
      :key="i"
      class="error-msg"
    >
      <li>{{ item }}</li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.flex-editable {
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.text-and-image {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}
.comment-edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 10px;
}
.fake-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin: 10px 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 40%);
  border-radius: 5px;
}
.text-editor {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 65%;
}
.image-editor {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 30%;
  height: 100%;
  min-height: 250px;
  margin: 0 20px;
  overflow: auto;
}
.error-msg {
  color: $error-red;
}
.editable-comment {
  padding: 5px 15px;
  margin: 5px 0;
  display: grid;
  grid-template:
    "title showImage" 32px
    "text ." auto
    "text actions" 30px / auto 190px;
  overflow-y: hidden;
}
.title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  height: 24px;
  padding: 0 20px !important;
  font-size: 14px !important;
}
.show-image {
  grid-area: showImage;
}
.show-image .v-icon {
  margin-left: 0.5em;
}
.text {
  grid-area: text;
  color: $grey-medium;
  padding: 0 20px !important;
}
.warning-message {
  color: $warning-orange;
}
.warning-message-section {
  margin-bottom: 10px;
}
</style>
