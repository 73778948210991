<script>
import { FROM_HISTORY } from '@src/utils/consts'
// eslint-disable-next-line no-unused-vars
const $gettext = s => s // to force vue-i18n-extract to see translations

export default {
  name: 'Navigation',
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    currentStep: {
      type: String,
      default: () => '',
    },
    documentVersion: {
      type: Object,
      default: () => {},
    },
    historicDocumentVersion: {
      type: Object,
      default: () => {},
    },
    indexFrom: {
      type: String,
      required: true,
    },
    reducedView: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'seeHistoricDocumentVersion',
    'changeStep',
    'goBack',
  ],
  computed: {
    showPreviousStepButton () {
      if (this.lockedMode) {
        const firstStep = this.historicDocumentVersion.stepTransitions[0].currentStepTemplate.type
        return this.currentStep === firstStep
      } else {
        return false
      }
    },
    showNextStepButton () {
      if (this.lockedMode) {
        const lastStep = this.historicDocumentVersion.stepTransitions.slice(-1)[0].currentStepTemplate.type
        return this.currentStep === lastStep
      } else {
        return false
      }
    },
    otherVersions () {
      return !this.lockedMode && this.documentVersion
        ? this.documentVersion.document.versions.filter(
          version => version.index !== this.documentVersion.index,
        ).sort((v1, v2) => v1.index - v2.index)
        : []
    },
    canISeePreviousIndexes () {
      return !!this.otherVersions.length
    },
  },
  methods: {
    seeHistoricDocumentVersion (versionDoc) {
      this.$emit('seeHistoricDocumentVersion', versionDoc)
    },
    changeStep (key) {
      this.$emit('changeStep', key)
    },
    handleGoBack () {
      this.$emit('goBack')
    },
    getBackButtonMessage () {
      if (this.lockedMode) {
        if (this.indexFrom === FROM_HISTORY) {
          return this.$gettext('Go back to the history')
        } else {
          return this.$gettext('Go back to last index')
        }
      } else {
        return this.$gettext('Go back to the menu')
      }
    },
  },
}
</script>
<template>
  <div class="card document-navigation">
    <div
      class="go-back hover-left"
      @click="handleGoBack"
    >
      <v-icon
        color="secondary"
        icon="fas fa-arrow-left"
        start
      />
      {{ getBackButtonMessage() }}
    </div>
    <v-menu
      v-if="canISeePreviousIndexes"
      class="document-version-navigation"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          variant="text"
          color="secondary"
          prepend-icon="fas fa-backward"
          v-bind="props"
        >
          {{ $gettext('See previous indices') }}
        </v-btn>
      </template>
      <v-list density="compact">
        <v-list-item
          v-for="versionDoc in otherVersions"
          :key="versionDoc.id"
          @click="seeHistoricDocumentVersion(versionDoc)"
        >
          <v-list-item-title>{{ $gettext('Index') }} {{ versionDoc.index }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div
      v-if="lockedMode"
      class="document-version-step-navigation"
    >
      <v-btn
        color="secondary"
        size="small"
        variant="text"
        :disabled="showPreviousStepButton"
        icon="fas fa-arrow-left"
        @click="changeStep(-1)"
      />
      <v-btn
        color="secondary"
        size="small"
        variant="text"
        :disabled="showNextStepButton"
        icon="fas fa-arrow-right"
        @click="changeStep(+1)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.document-navigation {
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.go-back {
  color: $secondary;
}
.go-back:hover {
  cursor: pointer;
}
.document-version-navigation {
  max-height: 90vh;
}
.document-version-step-navigation {
  flex-grow: 0;
  display: flex;
  gap: 2rem
}
</style>
