<script>
import CommentsPopup from '@comp/document/comments/CommentsPopup.vue'

export default {
  name: 'AllCommentsPopup',
  components: {
    CommentsPopup,
  },
  props: {
    popupShown: {
      type: Boolean,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    allThreads: {
      type: Array,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:popupShown',
    'close',
  ],
  data () {
    return {
      threads: [],
    }
  },
  computed: {
    title () {
      return this.$gettext('Trade Contributions')
    },
    commentPopupShown: {
      get () {
        return this.popupShown
      },
      set (value) {
        this.$emit('update:popupShown', value)
      },
    },
  },
  methods: {
    onCommentsPopupClose () {
      this.$emit('close')
    },
  },
}
</script>
<template>
  <CommentsPopup
    v-model:popup-shown="commentPopupShown"
    :title="title"
    :document-version="documentVersion"
    :threads="allThreads"
    :is-oman-type="isOmanType"
    @close="onCommentsPopupClose"
  />
</template>
