<script>
import { backUrl } from '@src/config'
import axios from 'axios'
import download from 'downloadjs'
import {
  getDataStep5,
  uploadTemplate,
} from '@comp/projectSettings/step5ApprovalSheetTemplate/queries'

export default {
  name: 'FormStep5',
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
  },
  data () {
    return {
      projectId: null,
      templateFile: null, // a browser File object (with name, size, type attributes) or null
      project: null,
      ready: false,
      approvalSheetTemplateUrl: null,
    }
  },
  computed: {
    templateFilename: function () {
      const url = this.approvalSheetTemplateUrl
      return url ? url.split('/').at(-1) : null
    },
  },
  watch: {
    async step () {
      await this.setData()
    },
  },
  async mounted () {
    this.projectId = this.$route.params.id
    await this.setData()
  },
  methods: {
    addDropFile (e) {
      this.templateFile = e.dataTransfer.files[0]
    },
    addInputFile (e) {
      this.templateFile = e.target.files[0]
    },
    async setData () {
      if (this.step === 5) {
        return this.$graphqlQuery(getDataStep5, {
          id: this.projectId,
        }).then(async (response) => {
          this.project = response.project
          if (this.project.approvalSheetTemplate) {
            this.approvalSheetTemplateUrl = response.project.approvalSheetTemplate.url
          } else {
            this.approvalSheetTemplateUrl = null
          }
          this.templateFile = await this.getTemplateFile()
          this.ready = true
        })
      }
    },
    async saveData () {
      return this.$graphqlMutate(uploadTemplate, {
        id: this.projectId,
        approvalSheetTemplate: this.templateFile,
      })
    },
    removeFile () {
      this.approvalSheetTemplateUrl = null
      this.templateFile = null
    },
    formatNumber (number) {
      return new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3 }).format(number)
    },
    getHumanSize(rawSize) {
      const sizeLimit = 1024
      const units = [
        this.$gettext('B'),
        this.$gettext('KB'),
        this.$gettext('MB'),
      ]
      let size = rawSize
      for (const unit of units) {
        if (size < sizeLimit) {
          return `${this.formatNumber(size)} ${unit}`
        } else {
          size /= sizeLimit
        }
      }
      return `${this.formatNumber(size)} ${units.at(-1)}`
    },
    async getTemplateFile () {
      let url = this.approvalSheetTemplateUrl
      if (!url) {
        return null
      }
      if (url[0] === '/') {
        url = url.substring(1)
      }
      url = `${backUrl}${url}`
      return axios.get(url, { withCredentials: true, responseType: 'blob' }).then(response => new File(
        [response.data],
        this.templateFilename,
        { type: 'application/vnd.oasis.opendocument.text' },
      )).catch(err => {
        this.store.changeNotification({
          type: 'error',
          text: err,
          autoClose: false,
        })
        return null
      })
    },
    async downloadTemplate () {
      const file = await this.getTemplateFile()
      download(file, file.name, file.type)
    },
  },
}
</script>
<template>
  <section
    v-if="ready"
    class="form"
  >
    <div class="form-section">
      <h1>{{ $gettext('Approval sheet template') }}</h1>
      <v-card>
        <v-card-text>
          <div
            v-cloak
            @drop.prevent="addDropFile($event)"
            @dragover.prevent
          >
            <span
              v-if="!templateFile"
              class="dropzone"
            >
              <v-icon
                color="mediumgrey"
                icon="fas fa-file-arrow-down"
              />
              {{ $gettext('Drag and drop the document here') }}
              <input
                ref="fileToAdd"
                type="file"
                hidden
                @change="addInputFile"
              >
              <v-btn
                size="small"
                color="primary"
                @click="$refs.fileToAdd.click()"
              >
                {{ $gettext('Browse file') }}
              </v-btn>
            </span>
            <span
              v-else
              class="row filename-display"
            >
              <v-icon
                icon="fas fa-file-lines"
                color="secondary"
              />
              {{ templateFile.name }}
              ({{ getHumanSize(templateFile.size) }})
              <v-icon
                color="secondary"
                size="x-small"
                icon="fas fa-times-circle"
                @click="removeFile"
              />
            </span>
          </div>
        </v-card-text>
        <v-card-actions v-if="approvalSheetTemplateUrl">
          <v-btn
            prepend-icon="fas fa-download"
            color="secondary"
            variant="elevated"
            @click="downloadTemplate()"
          >
            {{ $gettext('Download template') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.dropzone {
  border: 4px dashed $grey-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.dropzone:hover {
  border-color: $primary;
}
.filename-display {
  gap: 1ch;
  background: $primary;
  color: $secondary;
  font-weight: 500;
  border-radius: 5px;
  padding: 14px;
  min-height: 10px;
  align-items: center;
}
.circular-loading {
  justify-content: center;
  margin: 20px 0;
}
:deep(.v-input--horizontal .v-input__append) { // stylelint-disable-line selector-class-pattern
  margin-inline-start: unset;
}
</style>
