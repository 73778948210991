<script>
import CommentsPopup from '@comp/document/comments/CommentsPopup.vue'

export default {
  name: 'InternalNotesPopup',
  components: {
    CommentsPopup,
  },
  props: {
    popupShownProp: {
      type: Boolean,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    allThreads: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
    'close',
  ],
  data () {
    return {
      threads: [],
    }
  },
  computed: {
    title () {
      return this.$gettext('Internal notes')
    },
    popupShown: {
      get () {
        return this.popupShownProp
      },
      set (value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  watch: {
    popupShownProp: {
      handler () {
        if (this.popupShownProp) {
          this.threads = this.allThreads
        }
      },
      immediate: true,
    },
  },
  methods: {
    onCommentsPopupClose () {
      this.$emit('close')
    },
  },
}
</script>
<template>
  <CommentsPopup
    v-model:popup-shown="popupShown"
    :title="title"
    :show-importance="false"
    :document-version="documentVersion"
    :threads="threads"
    @close="onCommentsPopupClose"
  />
</template>
