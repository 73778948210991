<script>
export default {
  name: 'StepBreadcrumbs',
  props: {
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    currentStep: {
      type: String,
      default: () => '',
    },
    documentVersion: {
      type: Object,
      default: () => null,
    },
    historicDocumentVersion: {
      type: Object,
      default: () => {},
    },
    steps: {
      type: Object,
      default: () => {},
    },
    firstLoadAnimation: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    _getStepIndex (offset, actualStepsTypes) {
      const currentStepIndex = actualStepsTypes.indexOf(this.currentStep)
      switch (offset) {
        case 'previous':
          return currentStepIndex > 0 ? currentStepIndex - 1 : null
        case 'current':
          return currentStepIndex
        case 'next':
          return currentStepIndex < actualStepsTypes.length - 1 ? currentStepIndex + 1 : null
        default:
          return null
      }
    },
    _getStepTemplateKey (offset, documentVersion) {
      switch (offset) {
        case 'previous':
          return documentVersion.previousStepTemplate || null
        case 'current':
          return documentVersion.currentStepTemplate
        case 'next':
          return documentVersion.nextStepTemplate || null
        default:
          return null
      }
    },
    getStep (offset) {
      const noStep = { label: '', icon: '' }
      if (this.lockedMode && this.historicDocumentVersion) {
        const actualStepsTypes = this.historicDocumentVersion.stepTransitions.map(o => o.currentStepTemplate.type)
        const stepIndex = this._getStepIndex(offset, actualStepsTypes)
        return stepIndex != null ? this.steps[actualStepsTypes[stepIndex]] : noStep
      }
      const stepTemplateKey = this._getStepTemplateKey(offset, this.documentVersion)
      return (stepTemplateKey != null && stepTemplateKey.type) ? this.steps[stepTemplateKey.type] : noStep
    },
  },
}
</script>
<template>
  <transition name="step">
    <div class="steps">
      <div class="step step-previous">
        <transition
          :name="firstLoadAnimation ? 'fade-step' : 'none'"
          mode="out-in"
        >
          <div :key="`${currentStep}1`">
            {{ $gettext(getStep('previous').label) }}
          </div>
        </transition>
      </div>
      <div class="step-previous-arrow" />
      <div class="step step-current">
        <transition
          :name="firstLoadAnimation ? 'step' : 'none'"
          mode="out-in"
        >
          <div
            :key="`${currentStep}2`"
            class="current-step"
          >
            <span>
              {{ $gettext(getStep('current').label) }}
            </span>
            <v-icon
              color="primary"
              class="step-icon"
              :icon="getStep('current').icon"
            />
          </div>
        </transition>
      </div>
      <div class="step-current-arrow" />
      <div class="step step-next">
        <transition
          :name="firstLoadAnimation ? 'fade-step' : 'none'"
          mode="out-in"
        >
          <div :key="`${currentStep}3`">
            {{ $gettext(getStep('next').label) }}
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.steps {
  display: flex;
  height: 50px;
  background: linear-gradient(90deg, $secondary 50%, $grey-light 50%);
}
.step {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.step-previous, .step-next {
  font-size: 1em;
  background-color: $grey-light;
  color: $grey-medium;
  width: 30%;
}
.step-current {
  position: relative;
  font-size: 1.3em;
  background-color: $secondary;
  color: $white;
  width: 40%;
}
.step-previous-arrow {
  content: '';
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid $grey-light;
}
.step-current-arrow {
  content: '';
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid $secondary;
}
.current-step {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1ch;
}
.step-icon {
  opacity: 0.5 !important;
  font-size: 2rem !important;
}
</style>
