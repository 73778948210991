<script>
export default {
  name: 'ChipsAutocomplete',
  props: {
    name: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    allowNewValue: {
      type: Boolean,
      default: () => true,
    },
    itemValue: {
      type: String,
      default: () => 'id',
    },
    itemDisplay: {
      type: Function,
      default: o => o,
    },
    init: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    lockedValue: {
      type: String,
      default: () => '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'retrieveData',
    'userTyping',
  ],
  data () {
    return {
      filteredList: [],
      searchInput: '',
    }
  },
  watch: {
    init: {
      handler () {
        this.filteredList = this.init.slice()
      },
      immediate: true,
    },
  },
  methods: {
    onRemoveChip (item) {
      let index
      if (typeof item === 'object') {
        index = this.filteredList.findIndex(o => o[this.itemValue] === item[this.itemValue])
      } else {
        index = this.filteredList.indexOf(item)
      }
      this.filteredList.splice(index, 1)
      this.$emit('retrieveData', this.filteredList)
    },
    onKeydown (event) {
      setTimeout(() => {
        this.$emit('userTyping', event.target.value)
      }, 100)
    },
    handleChangeData (event) {
      if (this.lockedValue && !event.map(o => o[this.itemValue]).includes(this.lockedValue)) {
        const obj = {}
        obj[this.itemValue] = this.lockedValue
        this.filteredList.unshift(obj)
      }
      this.searchInput = ''
      this.$emit('retrieveData', this.filteredList)
    },
    isItemLockedValue (item) {
      return this.$gettext(item.title) !== this.$gettext(this.lockedValue)
    },
    canCloseItem (item) {
      return !this.disabled && this.isItemLockedValue(item)
    },
    getChipColor (item) {
      return this.isItemLockedValue(item) ? 'secondary' : 'lightgrey'
    },
  },
}
</script>
<template>
  <v-autocomplete
    v-if="!allowNewValue"
    v-model="filteredList"
    :items="list"
    :label="name"
    :item-title="itemDisplay"
    :item-value="itemValue"
    :disabled="disabled"
    :return-object="itemValue ? true : false"
    :menu-props="{closeOnClick: true}"
    :update:search="searchInput"
    :rules="rules ? rules : []"
    color="secondary"
    multiple
    chips
    :hide-details="hideDetails"
    @update:model-value="handleChangeData"
    @focus="onKeydown"
    @keydown="onKeydown"
  >
    <template v-slot:chip="{ props, item }">
      <v-chip
        v-bind="props"
        variant="elevated"
        :color="getChipColor(item)"
        :closable="canCloseItem(item)"
        size="small"
        @click:close="onRemoveChip(item)"
      />
    </template>
  </v-autocomplete>
  <v-combobox
    v-else
    v-model="filteredList"
    :items="list"
    chips
    color="secondary"
    :label="name"
    :item-title="itemDisplay"
    :item-value="itemValue"
    multiple
    :disabled="disabled"
    :return-object="itemValue ? true : false"
    class="autocomplete-hide-arrow"
    :update:search="searchInput"
    :rules="rules ? rules : []"
    :hide-details="hideDetails"
    @update:model-value="handleChangeData"
    @keydown="onKeydown"
    @focus="onKeydown"
  >
    <template v-slot:chip="data">
      <v-chip
        v-bind="data.attrs"
        variant="elevated"
        color="secondary"
        :closable="!disabled"
        size="small"
        @click:close="onRemoveChip(data.item)"
      >
        {{ itemDisplay(data.item) }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <div>{{ itemDisplay(data.item) }}</div>
    </template>
  </v-combobox>
</template>
<style lang="scss">
.autocomplete-hide-arrow .v-input__append-inner { // stylelint-disable-line selector-class-pattern
  visibility: hidden;
}
</style>
