<script>
export default {
  name: 'TradeContributionsButtons',
  components: {},
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    tradeContributions: {
      type: Array,
      required: true,
    },
    allTrades: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'nextStep',
    'newTradeContribution',
  ],
  data () {
    return {}
  },
  computed: {
    filteredTrades () {
      return this.allTrades.filter(
        trade => !this.tradeContributions.map(tc => tc.trade.acronym).includes(trade.acronym),
      )
    },
    canIAdvanceToNextStep () {
      return this.documentVersion.permissions.canIAdvanceToNextStep
    },
  },
  methods: {
    nextStep () {
      this.$emit('nextStep')
    },
    createTradeContributionEvent (trade) {
      this.$emit('newTradeContribution', trade)
    },
  },
}
</script>
<template>
  <div class="step-btn-container trade-contributions-buttons">
    <v-menu
      class="trades-menu"
      transition="scroll-y-transition"
      activator="#activator-btn"
      max-height="250px"
    >
      <v-list density="compact">
        <v-list-item
          v-for="trade in filteredTrades"
          :key="trade.id"
          @click="createTradeContributionEvent(trade)"
        >
          <v-list-item-title>
            {{ `${trade.acronym} - ${trade.name}` }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      id="activator-btn"
      :color="!filteredTrades.length ? 'lightgray' : 'primary'"
      :disabled="!filteredTrades.length"
      class="ask-new-trade-btn"
      prepend-icon="fas fa-question"
    >
      {{ $gettext('Add a contribution trade') }}
    </v-btn>
    <v-btn
      :color="!canIAdvanceToNextStep ? 'lightgray' : 'primary'"
      :disabled="!canIAdvanceToNextStep"
      :class="!canIAdvanceToNextStep ? 'disabled-button' : ''"
      prepend-icon="fas fa-arrow-right"
      @click="nextStep"
    >
      {{ $gettext('Go to next step') }}
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.trade-contributions-buttons {
  margin-top: 0;
}
.trades-menu {
  z-index: 20;
}
.ask-new-trade-btn {
  margin-right: 10px;
}
</style>
