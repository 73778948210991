<script>
import Navigation from '@comp/document/Navigation.vue'
import Information from '@comp/document/Information.vue'
import SmallInformation from '@comp/document/SmallInformation.vue'
import StepBreadcrumbs from '@comp/document/StepBreadcrumbs.vue'
import Preview from '@comp/document/Preview.vue'
import Admissibility from '@comp/document/steps/Admissibility.vue'
import TradeContributions from '@comp/document/tradeContributions/TradeContributions.vue'
import PmEvaluation from '@comp/document/pmEvaluation/PmEvaluation.vue'
import PMEvaluationSubmission from '@comp/document/steps/PMEvaluationSubmission.vue'
import EvaluationSummarySubmission from '@comp/document/steps/EvaluationSummarySubmission.vue'
import EvaluationSummary from '@comp/document/steps/EvaluationSummary.vue'
import EndOfWorkflow from '@comp/document/steps/EndOfWorkflow.vue'
import { WORKFLOW_STEPS, FROM_PROJECT, FROM_HISTORY } from '@src/utils/consts'
import { archiveRoute, projectRoute } from '@src/router'
import { currentDocumentVersion } from '@comp/document/queries'
import { useIndexStore } from '@src/store/index.js'

export default {
  name: 'Document',
  components: {
    Navigation,
    Information,
    SmallInformation,
    StepBreadcrumbs,
    Admissibility,
    TradeContributions,
    PmEvaluation,
    PMEvaluationSubmission,
    EvaluationSummarySubmission,
    EvaluationSummary,
    EndOfWorkflow,
    Preview,
  },
  data () {
    return {
      reducedView: false,
      lockedMode: null,
      documentVersionId: null,
      documentVersion: null,
      steps: {},
      currentStep: null,
      firstLoadAnimation: false,
      historicDocumentVersion: null,
      indexFrom: null,
      docPreview: null,
      isOmanType: null,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    currentDocumentVersionId () {
      return this.lockedMode ? this.historicDocumentVersion.id : this.documentVersion.id
    },
    currentStepComponent () {
      return this.steps[this.currentStep].component
    },
  },
  async mounted () {
    this.documentVersionId = this.$route.params.id
    this.lockedMode = this.$route.params.locked
    this.indexFrom = this.$route.params.from || FROM_PROJECT
    this.steps = WORKFLOW_STEPS
    this.docPreview = this.store.docPreview
    await this.setData()
  },
  methods: {
    setData () {
      return this.$graphqlQuery(currentDocumentVersion, {
        id: this.documentVersionId,
      }).then(response => {
        this.documentVersion = response.documentVersion
        this.isOmanType = this.documentVersion.document.project.isOmanType
        this.store.changeProject(this.documentVersion.document.project)
        if (this.lockedMode) {
          this.seeHistoricDocumentVersion(this.documentVersion)
        } else {
          this.currentStep = this.documentVersion.currentStepTemplate.type
        }
      })
    },
    onSeeHistoricDocumentVersion (incompleteVersionDoc) {
      return this.$graphqlQuery(currentDocumentVersion, {
        id: incompleteVersionDoc.id,
      }).then(response => {
        this.seeHistoricDocumentVersion(response.documentVersion)
      })
    },
    seeHistoricDocumentVersion (versionDoc) {
      this.historicDocumentVersion = versionDoc
      this.lockedMode = true
      const stepTransitions = versionDoc.stepTransitions
      if (stepTransitions.map(st => st.currentStepTemplate.type).includes('EndOfWorkflow')) {
        this.currentStep = 'EndOfWorkflow'
      } else {
        this.currentStep = stepTransitions[stepTransitions.length - 1].currentStepTemplate.type
      }
    },
    goBack () {
      if (this.lockedMode && this.indexFrom === FROM_PROJECT) {
        this.goBackToDocumentVersion()
      } else if (this.lockedMode && this.indexFrom === FROM_HISTORY) {
        this.goBackToArchive()
      } else {
        this.goBackToProject()
      }
    },
    goBackToDocumentVersion () {
      this.lockedMode = false
      this.currentStep = this.documentVersion.currentStepTemplate.type
    },
    goBackToProject () {
      this.$router.push({
        name: projectRoute.name,
        params: {
          id: this.documentVersion.document.project.id,
        },
      })
    },
    goBackToArchive () {
      this.$router.push({
        name: archiveRoute.name,
        params: {
          id: this.documentVersion.document.project.id,
        },
      })
    },
    async nextStep (stepType) {
      this.firstLoadAnimation = true
      this.currentStep = stepType
      await this.setData()
    },
    onChangeStep (key) {
      // key is -1 or +1
      const stepTransitions = this.historicDocumentVersion.stepTransitions
      const stepTypes = stepTransitions.map(o => o.currentStepTemplate.type)
      const newStepIndex = stepTypes.indexOf(this.currentStep) + key
      this.currentStep = stepTypes[newStepIndex]
    },
    async onTradeContributionsChanged () {
      await this.$refs.information.getData()
    },
    async onPreviewUpdateRequired () {
      if (this.docPreview && this.$refs.preview && this.$refs.preview.previewVisa) {
        await this.$refs.preview.setPreview()
      }
    },
    async onToggleDocPreview () {
      this.docPreview = !this.docPreview
      await this.onPreviewUpdateRequired()
      this.store.changeVisibility('docPreview')
    },
    onReduceDocumentInformationRequest () {
      this.reducedView = true
    },
    onExpandDocumentInformationRequest () {
      this.reducedView = false
    },
  },
}
</script>
<template>
  <section
    v-if="documentVersion"
    class="view document"
    :class="[ lockedMode ? 'locked-view' : '']"
  >
    <div
      class="left-column"
      :class="[ docPreview ? 'with-preview' : 'without-preview']"
    >
      <Navigation
        ref="navigation"
        :locked-mode="lockedMode"
        :current-step="currentStep"
        :document-version="documentVersion"
        :historic-document-version="historicDocumentVersion"
        :index-from="indexFrom"
        :reduced-view="reducedView"
        @see-historic-document-version="onSeeHistoricDocumentVersion"
        @change-step="onChangeStep"
        @go-back="goBack"
      />
      <div
        v-if="!reducedView"
        class="document-content"
      >
        <Information
          ref="information"
          :locked-mode="lockedMode"
          :current-step="currentStep"
          :document-version="documentVersion"
          :historic-document-version="historicDocumentVersion"
          :index-from="indexFrom"
          @see-historic-document-version="onSeeHistoricDocumentVersion"
          @change-step="onChangeStep"
          @go-back="goBack"
        />
        <StepBreadcrumbs
          :current-step="currentStep"
          :locked-mode="lockedMode"
          :document-version="documentVersion"
          :historic-document-version="historicDocumentVersion"
          :steps="steps"
          :first-load-animation="firstLoadAnimation"
        />
      </div>
      <div
        v-else
        class="document-content"
      >
        <SmallInformation
          ref="information"
          :locked-mode="lockedMode"
          :current-step="currentStep"
          :document-version="documentVersion"
          :historic-document-version="historicDocumentVersion"
          :index-from="indexFrom"
          @see-historic-document-version="onSeeHistoricDocumentVersion"
          @change-step="onChangeStep"
          @go-back="goBack"
        />
      </div>
      <transition
        name="step"
        mode="out-in"
        :duration="200"
      >
        <component
          :is="currentStepComponent"
          :ref="`step${currentStep}`"
          :current-step="currentStep"
          :locked-mode="lockedMode"
          :document-version-id="currentDocumentVersionId"
          :is-oman-type="isOmanType"
          @trade-contributions-changed="onTradeContributionsChanged"
          @next-step="nextStep"
          @preview-update-required="onPreviewUpdateRequired"
          @reduce-document-information-request="onReduceDocumentInformationRequest"
          @expand-document-information-request="onExpandDocumentInformationRequest"
        />
      </transition>
    </div>
    <transition
      name="step"
      mode="out-in"
      :duration="200"
    >
      <Preview
        ref="preview"
        :current-step="currentStep"
        :document-version="lockedMode ? historicDocumentVersion : documentVersion"
        :locked-mode="lockedMode"
        :doc-preview="docPreview"
        @toggle-doc-preview="onToggleDocPreview"
      />
    </transition>
  </section>
</template>
<style lang="scss" scoped>
.locked-view {
  transition: 0.5s;
  background-color: #ADBCCA;
}
.document {
  transition: 0.5s;
}
.left-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.3s;
}
.left-column.with-preview {
  width: 55%;
}
.left-column.without-preview {
  width: calc(100% - 50px - 8px - 20px);
}
.document-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
